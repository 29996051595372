.cockpitbutton{
    display:flex;
    justify-content: space-between;
    gap: 1rem
}
.cockpitbutton > button{
    flex: 1
}

.cockpitmenu{
    display: flex;
    gap: 1rem;
    height: 100%;
    flex-direction: column;
    overflow:auto
}

.action-left-options{
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: flex-start;
}

.action-right-options{
    display:flex;
    gap:1rem
}

.product-service-list{
    display:flex;
    flex-direction: column;
    gap:.5rem
}

.product-service-list li{
    display:flex;
    gap:1rem;
    justify-content: space-between;
    padding: 0
}

.simple-label,
.simple-flex{
    display:flex;
    gap:1rem;
    justify-content: space-between;
    padding: 0;
    flex: .85;
    align-items: center;
}

.single-line{
    border: 1px solid black;
    width: 100%;
    flex: 1;
}

.product-service-list .simple-text{
    flex:.15;
}

.product-service-list .simple-value{
    flex:.30;
}

.observation-service-list{
    display:flex;
    gap: .5rem;
    flex-direction: column;
}

.observation-service-list li{
    padding: 0
}

.observation-service-list li > div{
    width: 100%
}

.proposal-resume-list .nopadding{
    padding: 0
}

.proposal-resume-list  div {
    padding: 0
}

.proposal-resume-list .MuiListItemButton-gutters{
    padding: 0rem 1rem;
    flex: 1
}

.proposal-resume-list .MuiListItemButton-gutters.flexunset{
    flex: unset
}

.proposal-resume-list p{
    white-space: normal;
    overflow-wrap: break-word;
}

.product-service-list-wrapper{
    display:flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 0.25rem
}

.financeirorow--true,
.financeirorow--IN{
    color: #24a024;
}

.financeirorow--OUT{
    color: #dc3545;
}

.filter-headers {
    display: flex;
    gap: .5rem;
    max-width: 40rem;
    padding-top: .5rem;
}

.mixed-launch .MuiDataGrid-main{
    font-size: 0.9rem;
}