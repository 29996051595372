.report-main-table {
    overflow: auto;
    height: 100%;
}

.report-main-screen{
    flex: 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: white;
}

.report-resume-row,
.report-main-row,
.report-main-headers {
    display: flex;
}

.report-main-headers{
    font-weight: bold;
    border-bottom: 2px solid black;
    padding-bottom: .5rem;
}

.report-main-title {
    border-top: 2px solid black;
    padding: 1rem 0rem;
}

.report-extra-info,
.report-resume-row {
    background: #dedede;
}

.report-resume-row{
    border-top: 2px solid black;
    font-weight: bold;
}

.report-main-cell{
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.apuration-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: start;
}


.apuration-grid > div{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    min-width:10rem
}

.apuration-grid .headers{
    font-weight: bold;
}

.accounts-apuration-grid {
    display:flex;
    flex-direction: column;
    min-width:10rem;
    align-items: flex-start;
}

.accounts-apuration-grid > div{
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 40rem;
}

.accounts-apuration-grid > div > span{
    min-width: 10rem;
    display: flex;
    justify-content: flex-end;
}

.accounts-apuration-grid .headers {
    border-bottom: 2px solid black;
    font-weight: bold;
}